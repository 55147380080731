import React from 'react';
import '../App.css';

const Hero = () => {
  return (
    <div className="hero-container flex justify-center items-center h-screen">
      <div className="glassmorphism">
        <h1 className="text-4xl text-white" id="Hero">
          Stay Ahead of the Market: Real-Time
          <br />
          Alerts for <span style={{ color: 'blue', fontStyle: 'italic' }}>Smart</span> Trading Decisions
        </h1>
        <div className="container">
          <form  className='frm' action="https://submit-form.com/bfrWfLliS">
            <input
              className="inp"
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              required
            />
            <button id="button" type="submit"> 
              🚀 Sign Me Up!
            </button>
          </form>
        </div>
        <div className="hero-content">
          <p id="waitlistText" className="text-2xl text-white p-2 font-italic">
            Join the waitlist for more updates 📈
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
