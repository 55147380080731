import React, { useState } from 'react';
import '../App.css';


const FAQ = () => {
    const [faqs, setFaqs] = useState([
        { question: 'What is the difference between TheBeaconAI and platforms like Tradigview?', answer: "TradingView is a powerful platform that offers a wide range of indicators to help you analyze the markets. However, TheBeaconAI goes beyond just providing indicators. It enhances your trading efficiency with AI-driven insights. TheBeaconAI sends you email alerts for key support and resistance zones on your chosen time frame, helping you enter trades at optimal points. It also analyzes cash flow among markets to predict bullish and bearish divergences, offering comprehensive tools for making informed trading decisions.", isOpen: false },
        
  
    ]);

    const toggleFAQ = (index) => {
        setFaqs(faqs.map((faq, i) => (
            i === index ? { ...faq, isOpen: !faq.isOpen } : faq
        )));
    };

    const handleContactClick = () => {
        window.location.href = 'mailto:jahan@thebeaconai.com';
    };



    return (
        <div className="text-center text-white">
        <h1 className="text-6xl mb-6">FAQ</h1>
        <ul className="space-y-4">
            {faqs.map((faq, index) => (
                <li key={index} className="p-3">
                    <button 
                        onClick={() => toggleFAQ(index)} 
                        className="text-lg glassmorphism text-white py-2 px-4 rounded border"
                    >
                        {faq.question}
                    </button>
                    {faq.isOpen && (
                        <div className="mt-2 flex justify-center">
                            <p className="max-w-lg  text-gray-200 p-2 rounded text-center glassmorphism">
                                {faq.answer}
                            </p>
                        </div>
                    )}
                </li>
            ))}
        </ul>
       

<button className='glassmorphism-button' style={{marginTop:'200px'}} onClick={handleContactClick}>
    contact
</button>
    </div>
);
};

export default FAQ;
