import img from './assets/logotba.PNG';


const Navbar = () => {
    return(
        <nav className='text-white text-2xl p-3 ' id="pop">TheBeaconAI
        
        </nav>
        
    )
}

export default Navbar;