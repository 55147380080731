// src/components/Feature.js
import React, {useEffect } from 'react';
import '../App.css';
import Typewriter from 'typewriter-effect/dist/core';



const Feature = ({ title, description, image, image2, tldr }) => {
  useEffect(() => {
    new Typewriter('#typewriter', {
      strings: [
        `<span style="font-weight: bold; color: pink;">High Alert: BTC/USDT Oversold</span><br/>
        BTC/USDT RSI 14 for 15m has dropped below 30, currently oversold at 27.28. Stay vigilant and consider this as a potential buying opportunity!<br/><br/>
        <span style="font-weight: bold; color: pink;">High Alert: AAPL Golden Cross</span><br/>
        AAPL EMA 50 has crossed above EMA 200 on the 1h chart, indicating a bullish trend. This suggests a potential upward movement. Consider this as a possible buying opportunity!`
      ],
      autoStart: true,
      html: true,
      loop: true
    });
  }, []);

  return (
    <div className="flex flex-col md:flex-row justify-between items-center my-16 p-5 border m-4">
      <div className="w-full md:w-1/2 p-6 glassmorphism">

        <h2 className="text-3xl sm:text-xl font-bold text-white mb-4">{title}</h2>
        <p className='text-gray-500'>{tldr}</p>
        <br/>
        <p className="text-lg sm:text text-white">{description}</p>
      </div>
      <div className="w-full md:w-1/2 p-6">
        <div className="glassmorphism">
          <div className='text-gray-400 mb-4'>

          </div>
          <img src={image} alt="Feature" className="rounded-lg" />
          <br />
          <img src={image2} />
        </div>
      </div>
    </div>
  );
};

export const Feature2 = ({title, description, image3, tldr2 }) => {
  return(
    <div className='flex flex-col md:flex-row justify-between items-center my-16 p-5 border m-4'>
      <div className="card">
        <div className="header">
          <div className="top">
            <div className="circle">
              <span className="red circle2"></span>
            </div>
            <div className="circle">
              <span className="yellow circle2"></span>
            </div>
            <div className="circle">
              <span className="green circle2"></span>
            </div>
            <div className="title">
              <p id="title2">Key Indicators</p>
            </div>
          </div>
        </div>
        <div className="code-container">
          <div className="area ml-4" id="code"  name="code" readOnly>
          <div id="typewriter" className="text-white"></div>
         
          </div>
        </div>
      </div>
      <div className='w-full md:w-1/2 p-6 glassmorphism'>
        <h2 className='text-3xl sm:text-xl font-bold text-white mb-4'>{title}</h2>
        <p className='text-gray-500'>{tldr2}</p>
        <p className='text-lg sm:text text-white'>{description}</p>
      </div>
    </div>
  )
}

export default Feature;



