

import React from 'react';
import Spline from '@splinetool/react-spline';

const SplineBackground = () => {
  return (
    <div className="spline-background">
      <Spline scene="https://prod.spline.design/YRIEgsjKuKeG9Il3/scene.splinecode" />
    </div>
  );
};

export default SplineBackground;