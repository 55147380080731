import Navbar from './components/Navbar';
import './App.css';
import Background from './components/Background'
import Hero from './components/Hero'
import Feature from './components/Feature';
import { Feature2 } from './components/Feature';
import FAQ from './components/FAQ'

import React, {useEffect} from 'react';

import supres from './components/assets/supres.png'
import srmail from './components/assets/srmail.png'
import RSIoversold from './components/assets/RSIoversold.png'
import Diagram from './components/assets/Diagram.png'

//vercel analytics
import { Analytics } from "@vercel/analytics/react"


const App = () => {




  return(
    <div>
       <Background />
      <div id="content">
        <Navbar id='Hero'/>
        <Hero />

        <div className='glassmorphism w-50' style={{marginBottom:'80px'}}> 
          <p className='text-white text-3xl font-bold sm:text-2xl md:text-2xl titpara'> Get Email alerts when your favourite stocks or crypto show important signals!</p>

        </div>
        
        <div className='text-white text-4xl text-center' id='Hero'> Features</div>
        <Feature
          title="Email Notification of Support and Resistance"
          tldr='TLDR; Get an Email Notification When Your Favorite Crypto or Stock Hits Support/Resistance Zone'
          description="You're probably wondering how do we identify the support and the resistance zones and send you an automated mail instantly, to answer that; we use a mathematically backed formula to calculate the fibonacci retracement levels which is far more accurate than randomly drawing lines on your chart. Saying that you can also manually set the look back period ( eg: support/resistance for 15m,1h, etc. )"
          image={supres}
          image2={srmail}
         
        />
        <Feature2 title='Email Notification of Key Indicators'
        tldr2=' TLDR; Get Instant Alerts for Key Market Indicators'
        description='Imagine receiving an email the moment crucial market indicators are triggered, like EMA crosses or RSI levels. Our platform continuously scans the markets using advanced algorithms, ensuring you never miss an important signal. Customize your preferences to receive alerts for specific indicators and timeframes, keeping you ahead in the fast-paced world of trading.'
        image3={RSIoversold}
        />
        <Feature 
        title='SMT Divergence Scanning for Cash Flow Predictions'
        tldr=' TLDR; Identify Market Trends with cash flow movements across markets'
        description='Stay informed about the hidden cash flow movements between markets with our cashflow scanning feature. This sophisticated tool analyzes market trends to provide you with reliable predictions, helping you make informed trading decisions. Our advanced AI technology uncovers patterns that others might miss, giving you a strategic edge in the market.'
        image={Diagram}
        
        />

        <FAQ />
        <Analytics />
      </div>
      
      
    </div>
   
  )
}

export default App;